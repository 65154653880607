import { faBars, faEarthAmericas, faHouse, faRecordVinyl } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import { Link, useLocation } from 'react-router-dom'
import { faFacebookF, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import $ from "jquery";


export default function SideBar() {

  const [showNavBar, setShowNavBar] = useState(false);
  const location = useLocation()

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // console.log($(window).scrollTop(), $(window).height(), $(document).height());
      // if ($(window).scrollTop() === 0 || $(window).scrollTop() + $(window).height() >= $(document).height() - 100) {        
      if ($(window).scrollTop() === 0) {        
        setShowNavBar(false);
      } else {
        setShowNavBar(true);
      }
    };
    console.log($(window).scrollTop());
    // Attach the scroll event listener when the component mounts
    $(window).on("scroll", handleScroll);

    // Clean up the listener when the component unmounts
    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []);

  function toggleBurgerMenu() {
    if (!$(".burger-menu").hasClass("animate__bounceInLeft")) {
      $(".burger-menu").addClass("d-none animate__bounceInLeft")
      $(".burger-menu").removeClass("d-none animate__bounceOutLeft")
    }else {
      $(".burger-menu").addClass("d-none animate__bounceOutLeft")
      $(".burger-menu").removeClass("d-none animate__bounceInLeft")
    }
  }

  return (
    <>
        <nav>
            <div className={showNavBar || !(location.pathname === "/"  || location.pathname === "/home") ? "sidebar-menu" : "sidebar-menu opacity-0 z-n1" }>
                <ul className='parent-list-sidebar '>
                    <li className='list-sidebar'><FontAwesomeIcon className='icon-list-sidebar' icon={faHouse} /> <Link to={"/home"}> <h2 className='fs-5'>الرئيسية</h2></Link></li>
                    <li className='list-sidebar broadcast-sidebar'><FontAwesomeIcon className='icon-list-sidebar' icon={faRecordVinyl} /><Link to={"/broadcasting"}> <h2 className='fs-5'>البث المباشر</h2></Link></li>
                    <li className='list-sidebar'><FontAwesomeIcon className='icon-list-sidebar' icon={faEarthAmericas} /> <Link to={"/blog"}><h2 className='fs-5'>الأخبار</h2></Link></li>
                    <li className='list-sidebar-without-line cursor-pointer ' onClick={toggleBurgerMenu}><FontAwesomeIcon className='icon-list-sidebar h3' icon={faBars} /></li>
                </ul>
            </div>
            <div className='burger-menu animate__animated d-none'>
              <div className='header-burger-menu'>
                <img className='logo-burger-menu' src={logo} alt="" />
              </div>
              <ul className='list-burger-menu'>
                <Link to={"/home"} onClick={toggleBurgerMenu}><li className='item-burger-menu'>الرئيسية</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/media/series"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>المسلسلات</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/media/programs"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>البرامج</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/daily-news"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>التقارير اليومية</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/blog"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>الأخبار</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/schedule-time"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>مواعيد البث</li></Link>
                <hr className='line-burger-menu'/>
                <Link to={"/broadcasting"} onClick={toggleBurgerMenu} ><li className='item-burger-menu'>البث المباشر</li></Link>
              </ul>
              <div className="footer-burger-menu">
                <ul className='d-flex justify-content-center '>
                  <li><a target="_blank" rel='noreferrer' href="https://www.facebook.com/LTV.main"><FontAwesomeIcon className='text-white fs-5 pe-3' icon={faFacebookF} /></a></li>
                  <li><a target="_blank" rel='noreferrer' href="https://twitter.com/Ltv_Main"><FontAwesomeIcon className='text-white fs-5 pe-3' icon={faXTwitter} /></a></li>
                  <li><a target="_blank" rel='noreferrer' href="https://www.youtube.com/channel/UCk60HSbfpy-_kUKH0CU0asg"><FontAwesomeIcon className='text-white fs-5 pe-3' icon={faYoutube} /></a></li>
                </ul>
              </div>
            </div>
        </nav>
    </>
  )
}
