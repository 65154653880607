import React, { useCallback } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import { BrowserRouter, HashRouter, createBrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "jquery/dist/jquery.js";
import "./css/style.css";
import { createBrowserRouter, RouterProvider, createHashRouter } from "react-router-dom";

import Media from "./components/Media/Media";
import MediaDetails from "./components/MediaDetails/MediaDetails";
import TopHeader from "./components/TopHeader/TopHeader";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";
import Home from "./components/Home/Home";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Import slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Blog from "./components/Blog/Blog";
import 'animate.css';
import BlogDetails from "./components/BlogDetails/BlogDetails";
import ScheduleTime from "./components/ScheduleTime/ScheduleTime";
import Broadcasting from "./components/Broadcasting/Broadcasting";
import ApiProvider from './components/Store/ApiContext';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import FrequenciesPage from "./components/FrequenciesPage/FrequenciesPage";
import AboutLtvPage from "./components/AboutLtvPage/AboutLtvPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage/PrivacyPolicyPage";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import IntroLtv from "./components/IntroLtv/IntroLtv";
import YoutubePlaylistItems from "./components/YoutubePlaylistItems/YoutubePlaylistItems";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // react-toastify style file
import DailyNewsPage from "./components/DailyNewsPage/DailyNewsPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <><App/><Home/><Footer/></>,
  },
  {
    path: "/home",
    element: <><App/><Home/><Footer/></>,
  },
  {
    path: "/ltv_webapp",
    element: <><App/><Home /><Footer/></>,
  },
  {
    path: "/media",
    children: [
      {
        path: ":category",
        element: <><App/><Media /><Footer/></>,
      },
      {
        path: ":mediaRoute/:seasonNumber/:mediaSeason",
        element: <><App/><MediaDetails /><Footer/></>,
      },
    ],
  },
  {
    path: "/daily-news",
    element: <><App/><DailyNewsPage /><Footer/></>,
  },
  {
    path: "/blog",
    element: <><App/><Blog /><Footer/></>,
  },
  {
    path: "/news/:id",
    element: <><App/><BlogDetails /><Footer/></>,
  },
  {
    path: "/schedule-time",
    element: <><App/><ScheduleTime /><Footer/></>,
  },
  {
    path: "/broadcasting",
    element: <><App/><Broadcasting /><Footer/></>,
  },
  {
    path: "/frequency",
    element: <><App/><FrequenciesPage /><Footer/></>,
  },
  {
    path: "/about-ltv",
    element: <><App/><AboutLtvPage /><Footer/></>,
  },
  {
    path: "/privacy-policy",
    element: <><App/><PrivacyPolicyPage /><Footer/></>,
  },
  {
    path: "/contact-us",
    element: <><App/><ContactUsPage /><Footer/></>,
  },
  {
    path: "/intro",
    element: <><IntroLtv /></>,
  },
  {
    path: "/registration",
    element : <><App/><RegistrationForm /><Footer/></>
  },
  {
    path : "*",
    element : <NotFound/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
        <>
          <ApiProvider>
            <RouterProvider router={router} />
            <ToastContainer toastClassName="custom-toast"/>
          </ApiProvider>
        </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
