import React, { createContext, useContext, useState } from 'react'
import $ from 'jquery';
import axios from 'axios';

export const apiContext = createContext(0)

export default function ApiProvider(props) {
  if (process.env.NODE_ENV === 'production') {
    var baseApi = "https://test.l-television.com/api/v1";
    console.warn = () => {}
    console.log = () => {}
  } else {
    var baseApi = "http://localhost:8080/api/v1";
  // var baseApi = "http://3.92.134.166:3000/api/v1";
  // var baseApi = "https://test.l-television.com/api/v1";

  }

  const [token, setToken] = useState(localStorage.getItem("token"));

  function getOption(data, options) {
    const arrayOption = []
    data?.split(",").forEach(ele => {
      const option = options.find((d) => d.value === ele )
      arrayOption.push(option)
    });
    return arrayOption;
  }
  async function responseApi(endPoint="", method, data={}) {
    const res = await axios({
        url : `${baseApi}/${endPoint}`,
        method,
        headers : {
          'Content-Type': 'application/json', 
        },
        data,
    })
    console.log(res);
    if (res.data) {
      return res.data;
    }
  }
  async function getData(endPoint="", page, size) {
    const res = await axios({
      url : `${baseApi}/listing/${endPoint}?page=${page}&size=${size}`,
      method : "get",
    })
    console.log(res);
    if (res.data.message === "success") {
      return res.data.data;
    }
  }

  function togglePopUp(className) {
    $(`.${className}`).toggle();
  };



return  <apiContext.Provider value={{baseApi, getOption, responseApi, getData, togglePopUp, token, setToken}}>
            {props.children}
        </apiContext.Provider>
}


export const useApi = ()=> {
    const context = useContext(apiContext);
    if (context === undefined) {
      throw new Error("useUser must be used within a UserContextProvider.");
    }
    return context;
  };