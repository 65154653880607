import React, { useEffect, useState } from 'react'
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import $ from "jquery";


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { useApi } from '../Store/ApiContext';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

export default function MediaSlider({endPoint}) {

    const {category} = useParams();
    const {getData} = useApi();

    const [data, setData] = useState([])

    const pagination = {
        clickable: true,
        enabled :  true,
        renderBullet: function (index, className) {
            return `<span class="${className}" style="font-size:${data[index]?.seasonName.length > 15 ? scaleFontSize(data[index]?.seasonName.length, 35) : ""};"> ${data[index]?.seasonName} </span>` ;
        },
    };
    const paginationMobile = {
        clickable: true,
        enabled : true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>' ;
        },
    };
    async function fetchData() {
        const data = await getData(`slider/${category}`)
        console.log(data);
        setData(data)
    }

    function scaleFontSize(textLength, maxFontSize, isMedia=false) {
        const height = isMedia ? window.innerHeight : 0;
        const width = isMedia ? window.innerWidth : 0;
        console.log(height, width);
        const fontSize = (maxFontSize + (1600 < width ? 45 : width > 1400 ? 25 : 0)) - textLength  * (height > 750 ? 0.1 : 0.6 ); // Adjust the scaling factor as needed
        return fontSize + "px";
    }

    useEffect(() => {
        fetchData()
        console.log(window.innerWidth);
    }, [category]);
    

  return (
    <section className='media-slider' >
    <Swiper
        dir="rtl"
        pagination={window.innerWidth > 870 ? pagination : paginationMobile}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction : true
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        // breakpoints={{
        //     766 : {
        //         pagination: paginationMobile
        //     },
        //     786 : {
        //         pagination: {
        //             enabled : true,
                    
        //         }
        //     }
        // }}
  >
    {data && data?.map((ele, i) => <>
        <SwiperSlide key={i}>
            <div className='header-media'>
                <img className='poster-media d-none d-lg-inline-block' src={ele.seasonBannerDesk} alt={"Banner " + ele.seasonName} />
                <img className='poster-media d-lg-none d-inline-block' src={ele.seasonBannerMob} alt={"Banner " + ele.seasonName} />
                <div className="layer2"></div>
                <div className="layer1">
                    <div className='info-media'>
                        <div className='header-info-media'>
                            <img className="logo-info-media" src={logo} alt="LTV" />
                            <h2 className='title-media' style={{fontSize : ele.seasonName.length > 15 ? scaleFontSize(ele.seasonName.length, 70, true) : ""}}>{ele.seasonName}</h2>
                        </div>
                        <div className='body-info-media'>
                            <ul className='d-flex mb-3 list-info-media-tags  justify-content-lg-start justify-content-center '>
                                <li className='me-2'>{ele.seasonType}</li>
                                {ele.seasonTags?.split(",").map((t, i) => <li className='list-info-1' key={i}>{t}</li>)}
                            </ul>
                            <ul className='d-flex mb-1 list-info justify-content-lg-start justify-content-center '>
                                <li className='me-2'>الموسم {ele.seasonNumber}</li>
                                <li className='list-info-2'>{moment(ele.seasonRelease).format("MMMM YYYY")}</li>
                            </ul>
                            {!ele.isNoEpisodeList ? <Link to={`/media/${ele.mediaRoute}/${ele.seasonNumber}/${ele.id}`}>
                                <div className='play-media d-flex align-items-center'>
                                    <svg className='img-play-media' xmlns="http://www.w3.org/2000/svg" width="1050" height="1143" viewBox="0 0 1050 1143" fill="none">
                                        <path d="M344.781 308.516C296.211 321.368 263.111 392.369 251.957 507.543C248.959 538.114 248.959 606.744 251.957 637.316C263.111 752.864 295.851 822.991 345.141 836.343C366.608 842.332 412.78 831.601 451.157 812.01C481.019 796.662 537.385 757.855 579.479 723.665C657.432 660.276 706.362 604.623 709.241 575.798C711.039 557.081 695.089 531.126 657.432 491.945C635.845 469.484 610.18 446.025 581.878 423.19C506.803 362.297 442.402 323.864 394.191 310.762C379.68 306.893 355.215 305.77 344.781 308.516Z" fill="#8F55F2"/>
                                        <path d="M216.348 3.46466C196.076 8.56864 183.475 14.4785 164.298 27.6414C81.8411 85.6656 26.2302 226.696 5.13647 432.199C-1.71216 498.013 -1.71216 645.76 5.13647 711.574C29.2436 947.7 99.6475 1098.94 202.103 1135.74C230.319 1145.68 276.068 1145.41 323.187 1134.94C394.138 1119.09 465.09 1089.81 527.276 1051.12C703.422 941.253 827.793 846.695 932.714 743.81C1017.36 660.803 1053.8 604.659 1049.69 564.634C1042.84 498.55 914.634 365.041 721.776 222.936C551.383 97.4853 410.849 22.806 308.394 4.00188C281.547 -1.1021 235.798 -1.37068 216.348 3.46466ZM301.819 119.513C358.252 131.87 468.377 186.939 563.71 250.336C599.871 274.244 673.836 327.701 705.34 352.415C800.125 427.363 896.827 518.429 923.948 557.918L933.536 571.886L922.852 587.467C908.059 608.689 819.301 695.725 771.36 735.751C600.967 878.125 442.627 978.324 328.665 1015.66C302.093 1024.53 290.861 1026.95 269.219 1028.02C243.743 1029.1 241.825 1028.83 231.415 1022.11C192.241 996.86 152.793 901.765 133.069 786.254C113.619 672.892 110.879 518.966 126.22 402.112C144.848 257.858 190.323 142.078 236.894 118.438C247.578 113.066 251.687 112.529 267.028 113.872C276.89 114.946 292.505 117.364 301.819 119.513Z" fill="#8F55F2"/>
                                    </svg>
                                    <svg className='img-play-media-hover' width="841" height="942" viewBox="0 0 841 942" fill="none">
                                        <g clip-path="url(#clip0_232_504)">
                                        <path d="M128.877 1.94717C115.989 7.72322 105.782 16.181 94.8535 30.2086C56.2935 79.5113 22.8886 185.853 8.35123 305.603C1.64963 360.991 0 392.656 0 468.57C0 541.802 1.54652 574.808 7.83572 631.021C24.6413 782.849 72.3773 911.882 121.66 938.596L127.846 942H146.92C168.056 941.897 178.057 940.762 194.862 936.327C305.49 907.137 471.896 806.366 649.025 661.346C692.74 625.658 716.969 603.895 758.725 562.946C804.811 517.769 824.71 496.316 835.536 480.122L841 472.077L835.536 464.135C818.73 439.69 793.264 411.738 752.745 373.265C690.781 314.576 633.353 265.995 572.73 220.818C470.453 144.698 401.787 100.656 320.646 58.9857C242.701 19.069 191.666 1.63774 147.951 0.193724C135.166 -0.218852 133.62 -0.115708 128.877 1.94717ZM287.138 230.823C320.852 238.147 363.33 259.085 414.984 293.741C461.277 324.89 504.27 359.547 539.531 394.306C582.112 436.08 597.268 461.04 591.803 480.019C580.978 517.46 502.93 591.414 406.324 655.776C353.432 690.948 324.255 704.666 285.592 712.402C261.569 717.146 247.547 716.115 232.803 708.482C194.862 688.679 170.015 634.528 158.777 546.959C155.683 523.03 154.962 503.226 155.477 460.318C155.993 416.173 156.611 407.715 161.87 375.328C175.376 292.606 204.244 241.963 244.866 230.204C250.537 228.554 278.581 228.967 287.138 230.823Z" fill="#8F55F2"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_232_504">
                                        <rect width="841" height="942" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <p className='title-play'>شاهد الأن</p>
                                </div>
                            </Link> : <></>}
                            <p className='desc-media'>{ele.seasonDesc}</p>
                        </div>
                    </div>
                </div>
            </div>    
    </SwiperSlide>
    </>)}
  </Swiper>
</section>
  )
}
