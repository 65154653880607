import React from 'react'
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import OverlayShareBtn from "../OverlayShareBtn/OverlayShareBtn";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function BlogItemSlider({data}) {
  return (
    <>
    <section className='blog-item-slider'>
    <Swiper
          dir="rtl"
          // slidesPerView={"auto"}
          // slidesPerGroup={6}
          // slidesPerGroupSkip={0}

          // onSlideNextTransitionEnd={3}
          grabCursor={false}
          centeredSlides={false}
          spaceBetween={20}
          navigation={{
            enabled : true,
          }}
          loop={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
            enabled : true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.15,
              centeredSlides : false,
              pagination: {
                enabled : false,
              },
              navigation: {
                enabled : false
              },
            },
            600: {
              slidesPerView: 2,
              centeredSlides: true,
              pagination: {
                enabled : false,
              },
              navigation: {
                enabled : false
              },
            },
            868: {
              slidesPerView: 3,
              pagination: {
                enabled : false,
              },
              navigation: {
                enabled : false
              },
            },
            991: {
              slidesPerView: 4,
              pagination: {
                enabled : false,
              },
              navigation: {
                enabled : false
              },
            },
            1200: {
                slidesPerView: 4.25,
                slidesOffsetAfter: 40,
                slidesOffsetBefore: 40,
            },
            1400: {
              slidesPerView: 5.38,
              // slidesOffsetAfter: 100,
              slidesOffsetBefore: 40,
            },
            1600: {
              slidesPerView: 5.3,
              // slidesOffsetAfter: 46,
              slidesOffsetBefore: 40,
            },
          }}
          modules={[Pagination, Navigation]}


          // className="mySwiper"
        >
          {/* <SwiperSlide>
            <div className='test-slider'>Slide 2</div>
            <div className='test-slider'>Slide 3</div>
            <div className='test-slider'>Slide 4</div>
        </SwiperSlide> */}
        {data && data?.map((ele, i) => <SwiperSlide className='d-flex' key={i}>
            <div className='card-blog'>
                <div className="img-card">
                    <Link to={`/news/${ele.id}`}>
                        <img className='w-100'  src={ele.imageArticle} alt={ele.titleArticle} />
                    </Link>
                </div>
                <div className='content-card'>
                  <div className='d-flex justify-content-between mb-2'>
                    <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                          {ele.categoryArticle}
                    </div>

                    <div className='d-flex justify-content-between align-items-center fs-14'>
                      <FontAwesomeIcon size='xs' icon={faClock} className='pe-1' />
                      <p className='fs-14'>{moment(ele.createdAt).format("L")} | {moment(ele.createdAt).format("LT")}</p>
                    </div>
                  </div>
                    
                    <Link to={`/news/${ele.id}`}>
                        <div className='title-card'>
                        <h3 className=''>{ele.titleArticle}</h3>
                        </div>
                    </Link>
                    <div className='desc-card'>
                        {/* <p className=''>اعلنت شركة روتانا ساينز حصولها على أكبر عقود للإعلانات الخارجية في العالم، واعلنت الشرك... {data[0].bodyArticle}</p> */}
                        {/* <p className='fs-14' dangerouslySetInnerHTML={{__html: ele.bodyArticle.slice(0, 50) + "..."}}></p> */}
                    </div>
                </div>
            </div>
          </SwiperSlide> )}
        </Swiper>
    </section>
    </>
  )
}
