import React, { useCallback, useEffect, useState } from "react";
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import $ from "jquery";
import { Link, NavLink, ScrollRestoration } from "react-router-dom";

export default function Navbar() {
  const [scrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      if ($(window).scrollTop() === 0) {
        console.log("Scrolled up");
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    $(window).on("scroll", handleScroll);

    // Clean up the listener when the component unmounts
    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []);

  // let getKey = useCallback(
  //   (location, matches) => {
  //     let match = matches.find((m) => (m.handle)?.scrollMode);
  //     if ((match?.handle)?.scrollMode === "pathname") {
  //       return location.pathname;
  //     }

  //     return location.key;
  //   },
  //   []
  // );

  // let getKey = useCallback(
  //   (location, matches) => {
  //     // let match = matches.find((m) => (m.handle)?.scrollMode);
  //     console.log(location);
  //     console.log(location.key);
  //     return location.pathname;
  //   },
  //   []
  // );

  return (
    <>
      <nav>
        <div className="navbar">
          <ul className={`navbar-top ${scrollUp ? "" : "navbar-down"}`}>
            <li className="me-2">
              <Link to={"/"}>
                <img className="logo-img" src={logo} alt="LTV" />
              </Link>
            </li>
              <NavLink
              to={"/home"}
              className={({ isActive, isPending }) =>
              isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
             الرئيسية
             </NavLink>
            <NavLink
              to={"/media/series"}
              className={({ isActive, isPending }) =>
                isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              المسلسلات
            </NavLink>
            <NavLink
              to={"/media/programs"}
              className={({ isActive, isPending }) =>
                isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              البرامج
            </NavLink>
            <NavLink
              to={"/daily-news"}
              className={({ isActive, isPending }) =>
                isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              التقارير اليومية
            </NavLink>
            <NavLink
              to={"/blog"}
              className={({ isActive, isPending }) =>
              isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              الأخبار
            </NavLink>
            <NavLink
              to={"/schedule-time"}
              className={({ isActive, isPending }) =>
                isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              مواعيد البث
            </NavLink>
            <NavLink
              to={"/broadcasting"}
              className={({ isActive, isPending }) =>
                isActive && !scrollUp ? "active-text-nav" : !scrollUp ? "text-nav" : "text-nav-fixed"
              }
            >
              البث المباشر
            </NavLink>
          </ul>
        </div>
      </nav>
    </>
  );
}
