import React, { useEffect, useState } from "react";
import MediaSlider from "../MediaSlider/MediaSlider";
import MediaItemSlider from "../MediaItemSlider/MediaItemSlider";
import { Link } from "react-router-dom";
import MediaItemSlider2 from "../MediaItemSlider2/MediaItemSlider2";
import { useApi } from "../Store/ApiContext";
import BlogItemSlider from "../BlogItemSlider/BlogItemSlider";

export default function Home() {

  const {getData} = useApi();
  
  const [data, setData] = useState({});
  const [dataArticles, setDataArticles] = useState([]);
  

  async function getMedia() {
      const data = await  getData(`season`);
      if (data) {
          setData(data)
      }
  }

  async function getArticles() {
    const data = await  getData("articles", 0, 20);
    if (data) {
      setDataArticles(data.articles)
    }
    console.log(data);
}

  useEffect(() => {
      getMedia();
      getArticles();

      return () => {
        setData([]);
        console.log("dead");
        
      } 
  }, []);
  

  return (
    <>
      <section className="home">
        <MediaSlider />

        { data?.categories?.length && data?.categories.map((ele, i) => <section className="media-section" key={i}>
          <div className="header-media-section d-flex justify-content-between align-items-center" >
            <div>
              <h2 className="text-white">{ele.categoryName}</h2>
            </div>
            <div >
              <Link to={`/media/${ele.categoryRoute}`}>
                <button className="all-category-btn">مشاهدة الكل</button>
              </Link>
            </div>
          </div>
          {i % 2 ? <MediaItemSlider2 data={data.data[ele.categoryRoute]} /> : <MediaItemSlider data={data.data[ele.categoryRoute]} />}
        </section> )  }

        {dataArticles?.length ? <section className="media-section">
          <div className="header-media-section d-flex justify-content-between align-items-center" >
            <div>
              <h2 className="text-white">الأخبار</h2>
            </div>
            <div >
              <Link to={`/blog`}>
                <button className="all-category-btn">مشاهدة الكل</button>
              </Link>
            </div>
          </div>
          {dataArticles?.length ? <BlogItemSlider data={dataArticles} /> : <></>}
        </section> : <></>}

        {/* {dataArticles?.length ? <section className="media-section">
          <div className="header-media-section d-flex justify-content-between align-items-center" >
            <div>
              <h2 className="text-white">الأخبار</h2>
            </div>
            <div >
              <Link to={`/blog`}>
                <button className="all-category-btn">مشاهدة الكل</button>
              </Link>
            </div>
          </div>
          {dataArticles?.length ? <BlogItemSlider data={dataArticles} /> : <></>}
        </section> : <></>} */}
            
      </section>      
    </>
  );
}
