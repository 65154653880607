import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import blogImg1 from "../../img/blog/blog-img-1.jpeg";
import blogImg2 from "../../img/blog/blog-img-2.jpeg";
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function BlogHeader({data}) {


  return (
    <>
    <div className='container-fluid'>
    {data?.length === 5 && <div className='row gx-2 gy-0'>
            <div className='col-xl-3' > 
              <div className='small-card d-md-flex d-xl-inline-block'>
                <Link to={`/news/${data[0].id}`}>
                  <div className='mb-2 small-card-slider'>
                      <img className='w-100 img-card-slider' src={data[0].imageArticle} alt="" />
                      <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              {data[0].categoryArticle}
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>{moment(data[0].createdAt).format("L")} | {moment(data[0].createdAt).format("LT")}</p>
                            </div>
                          </div>
                          <h6 className=''>
                              {data[0]?.titleArticle?.slice(0, 60)}{data[0]?.titleArticle?.length > 60 ? "..." : "" } 
                              {/* إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ... */}
                          </h6>
                        </div>
                      </div>
                    </div>
                </Link>
                <Link to={`/news/${data[1].id}`} >
                  <div className='ms-md-2 ms-xl-0 mb-2 mb-xl-0 small-card-slider'>
                    <img className='w-100 img-card-slider' src={data[1].imageArticle} alt="" />
                    <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              {data[1].categoryArticle}
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>{moment(data[1].createdAt).format("L")} | {moment(data[1].createdAt).format("LT")}</p>
                            </div>
                          </div>
                          <h6 className=''>
                            {data[1]?.titleArticle?.slice(0, 60)}{data[1]?.titleArticle?.length > 60 ? "..." : "" } 
                              {/* إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ... */}
                          </h6>
                        </div>
                        
                      </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-6'>
              <Link to={`/news/${data[2].id}`} >
                <div className='big-card mb-2 mb-xl-0'>
                  <img className='w-100 img-card-slider' src={data[2].imageArticle} alt="" />
                  <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              {data[2].categoryArticle}
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>{moment(data[2].createdAt).format("L")} | {moment(data[2].createdAt).format("LT")}</p>
                            </div>
                          </div>
                          <h6 className=''>
                            {data[2]?.titleArticle?.slice(0, 60)}{data[2]?.titleArticle?.length > 60 ? "..." : "" } 
                              {/* إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ... */}
                          </h6>
                        </div>
                        
                      </div>
                </div>
              </Link>
            </div>
            <div className='col-xl-3'>
              <div className='small-card d-md-flex d-xl-inline-block '>
                <Link to={`/news/${data[3].id}`}>
                  <div className='mb-2 small-card-slider '>
                    <img className='w-100 img-card-slider' src={data[3].imageArticle} alt="" />
                    <div className='layer-gradient-img-card'>
                      <div className='content-on-img-card'>
                        <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                          <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                            {data[3].categoryArticle}
                          </div>
                          <div className='d-flex justify-content-between align-items-center time-card'>
                            <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                            <p>{moment(data[3].createdAt).format("L")} | {moment(data[3].createdAt).format("LT")}</p>
                          </div>
                        </div>
                        <h6 className=''>
                            {data[3]?.titleArticle?.slice(0, 60)}{data[3]?.titleArticle?.length > 60 ? "..." : "" } 
                            {/* إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ... */}
                        </h6>
                      </div>
                      
                    </div>
                  </div>
                </Link>
                <Link to={`/news/${data[4].id}`} >
                  <div className='ms-md-2 ms-xl-0 mb-2 mb-xl-0 small-card-slider'>
                    <img className='w-100 img-card-slider' src={data[4].imageArticle} alt="" />
                    <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              {data[4].categoryArticle}
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>{moment(data[4].createdAt).format("L")} | {moment(data[4].createdAt).format("LT")}</p>
                            </div>
                          </div>
                          <h6 className=''>
                              {data[4]?.titleArticle?.slice(0, 60)}{data[4]?.titleArticle?.length > 60 ? "..." : "" }
                          </h6>
                        </div>
                      </div>
                  </div>
                </Link>
              </div>

            </div>
    </div> }
    </div>
    
    
    </>
  )
}
